<template>
	<footer class="footer">
		<div class="wrapper">
			<div class="footer-top">
				<div class="flex">
					<div class="col">
						<div class="desc bold">
							<b>Useful</b>
						</div>
						<ul class="nav">
							<li class="nav__item" v-for="item in $parent.textPageList" :key="item.id">
								<a class="desc" @click="$parent.goToPage(item)">
									{{item.title}}
								</a>
							</li>
						</ul>
					</div>
					<div class="col">
						<div class="desc bold">
							<b>Personal</b>
						</div>
						<ul class="nav">
							<template v-for="item in $parent.footerNav">
								<li v-if="item.path" class="nav__item" :key="item.title">
									<router-link class="desc" :to="item.path">
										{{item.title}}
									</router-link>
								</li>
								<li v-if="item.modal == 'contact'" class="nav__item" :key="item.title">
								<div class="desc hover-link" @click="$parent.openContactModal">
									{{item.title}}
								</div>
							</li>
							</template>
						</ul>
					</div>
					<div class="col">
						<div class="desc bold">
							<b>Faq</b>
						</div>
						<ul class="nav">
							<li class="nav__item" v-for="item in $parent.faq.slice(0,5)" :key="item.id">
								<a class="desc" @click="goToQuestion(item.id)">
									{{item.question}}
								</a>
							</li>
							<li class="nav__item" >
								<router-link class="desc" to="/faq">
									See all
								</router-link>
							</li>
						</ul>
					</div>

					<div class="col">
						<div class="desc bold">
							<b>Support</b>
						</div>
						<div class="desc">{{$parent.companyEmail}}</div>
					</div>

					<div class="col wrapper_social">
						<div class="social-links">
							<div v-for="link in $parent.socialLinks" :key="link.img" class="social_links__link">
								<a :href="link.link">
									<img :src="link.img" :alt="link.link"/>
								</a>
							</div>
						</div>
					</div>

				</div>
				
			</div>
			<div class="footer-bottom">
				<div class="flex">
					<div class="col">
						<router-link to="/" class="logo">
							<img src="./../img/logo.svg" class="img"/>
						</router-link>
						<div class="desc">
							© {{ new Date().getFullYear() }}<br/>
							Verator All Rights Reserved
						</div>
						<ul class="list">
							<li class="item">
								<img src="./../img/footerMethods/3ds.svg" class="img"/>
							</li>
							<li class="item">
								<img src="./../img/footerMethods/b.svg" class="img"/>
							</li>
							<li class="item">
								<img src="./../img/footerMethods/mc.svg" class="img"/>
							</li>
							<li class="item">
								<img src="./../img/footerMethods/visa.svg" class="img"/>
							</li>
						</ul>
					</div>

					<div  v-if="$parent.legalInfo" class="desc copiryght">
						{{$parent.legalInfo}}
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		
		}
	},
	methods: {
		goToQuestion(id) {
			this.$router.push({ path: '/faq', query: { id: id } });
		}
	},
	computed: {
		
	},
	mounted() {
	
	}
}
</script>
